import { render, staticRenderFns } from "./modifyPass.vue?vue&type=template&id=263d11a7&scoped=true&"
import script from "./modifyPass.vue?vue&type=script&lang=js&"
export * from "./modifyPass.vue?vue&type=script&lang=js&"
import style0 from "./modifyPass.vue?vue&type=style&index=0&id=263d11a7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "263d11a7",
  null
  
)

export default component.exports