<template>
  <div class="modifyPass">
    <AppHeader :type="1"></AppHeader>
    <div class="warp"
         style="min-height:calc(100% - 165px)">
      <div class="content">
        <img class="topImg"
             src="@/assets/modifyPass.png"
             alt="">
        <el-form class="form"
                 ref="form"
                 :model="form"
                 label-width="80px">
          <el-form-item label="账户名：">
            <div style="color:rgb(153,153,153);text-align:left">{{form.email}}</div>
          </el-form-item>
          <el-form-item label="当前登录密码：">
            <el-input type="password"
                      maxlength="64"
                      placeholder="请输入密码"
                      v-model="form.currentPass"></el-input>
          </el-form-item>
          <el-form-item label="新登录密码：">
            <el-input type="password"
                      maxlength="64"
                      placeholder="8-16位，必须包含数字及字母，大小写均可，支持特殊字符"
                      v-model="form.newPass"></el-input>
          </el-form-item>
          <el-form-item label="确认新登录密码：">
            <el-input type="password"
                      maxlength="64"
                      placeholder="确认密码"
                      v-model="form.newPassComfirm"></el-input>
          </el-form-item>
        </el-form>
        <div class="footBtn">
          <el-button type="primary"
                     @click="onSubmit">确定</el-button>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>
<script>
import AppHeader from '@/components/layer/AppHeader'
import AppFooter from '@/components/layer/AppFooter'
import { modifyPass } from '@/api/user'
import { sm3 } from '@/utils/sm3'

export default {
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      form: {
        email: '',
        currentPass: '',
        newPass: '',
        newPassComfirm: ''
      },
      loading: ''
    }
  },
  mounted() {
    this.form.email = JSON.parse(localStorage.getItem('userData')).email
  },
  methods: {
    validatePass(value) {
      if (!value && value !== '0') {
        return false
      }
      const reg = /^(?=.*[a-zA-Z])(?=.*\d)[^\s\n\t]{8,16}$/
      if (reg.test(value)) {
        return true
      }
      return false
    },
    onSubmit() {
      this.$message.closeAll()
      if (this.form.currentPass.trim() == '') {
        this.$message.error('当前登录密码不能为空')
      } else if (!this.validatePass(this.form.currentPass)) {
        this.$message.error('当前登录密码填写错误')
      } else if (this.form.newPass.trim() == '') {
        this.$message.error('新登录密码不能为空')
      } else if (!this.validatePass(this.form.newPass)) {
        this.$message.error('新登录密码填写不符合要求')
      } else if (this.form.newPassComfirm != this.form.newPass) {
        this.$message.error('新密码与确认密码信息不一致')
      } else if (this.form.currentPass == this.form.newPass) {
        this.$message.error('当前登录密码和新密码不能相同')
      } else {
        this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const data = {
          email: this.form.email,
          pwd: sm3(this.form.currentPass),
          newpwd: sm3(this.form.newPass)
        }
        modifyPass(data).then((res) => {
          this.$message.closeAll()
          this.loading.close()
          if (res.retCode == 0) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
             this.$router.go(-1)
          } else {
            this.$message.error(res.retMsg)
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .modifyPass {
    height: 100%;
    .content {
      ::v-deep .el-form-item {
        margin-bottom: 32px;
      }
      ::v-deep .el-form-item__label {
        width: 150px !important;
        color: #333333 !important;
      }
      ::v-deep .el-form-item__content {
        margin-left: 150px !important;
      }
      width: 1000px;
      height: 622px;
      box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
       margin: 0 auto;
      padding-top: 85px;
      .topImg {
        width: 100%;
        height: 157px;
      }
      .form {
        width: 600px;
        text-align: center;
        margin: 50px auto;
      }
      .footBtn {
        width: 100%;
        text-align: center;
        button {
          width: 160px;
          height: 50px;
          font-size: 18px;
          color: #fff;
          background: linear-gradient(
            -90deg,
            rgba(49, 97, 187, 1) 0%,
            rgba(42, 72, 156, 1) 100%
          );
          border-radius: 4px;
          border: none;
        }
      }
    }
  }
</style>
